<template>
  <el-Input
    ref="input"
    v-currency="options"
    :value="formattedValue"
    @input="handleInput"
  />
</template>

<script>
import { setValue, getValue } from "vue-currency-input";

export default {
  name: "ElCurrencyInput",
  props: {
    value: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      formattedValue: null,
      options: {
        distractionFree: false,
        currency: null,
        precision: 0,
        autoDecimalMode: false,
        valueAsInteger: true,
        hideGroupingSymbol: false
      }
    };
  },
  watch: {
    value: "setValue"
  },
  mounted() {
    this.setValue(this.value);
  },
  methods: {
    handleInput(value) {
      this.$emit("input", getValue(this.$refs.input));
      this.formattedValue = value;
    },
    setValue(value) {
      setValue(this.$refs.input, value);
    }
  }
};
</script>
