<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">Ачаалалтай цагийн төлбөр</h3>
      <div class="action-section">
        <el-button
          type="success"
          size="mini"
          icon="el-icon-plus"
          @click="openEditDialog('add', addPickHourList)"
          >Нэмэх</el-button
        >
      </div>
    </div>
    <div class="panel">
      <el-table
        :data="pickHourList"
        style="width: 100%; text-align: center"
        border
      >
        <el-table-column
          fixed
          label="№"
          prop="id"
          width="40px"
        ></el-table-column>
        <el-table-column label="7 хоног" prop="weekdays">
          <template slot-scope="scope">
            <el-tag
              size="mini"
              v-for="(wday, wdayIndex) in scope.row.weekdays"
              :key="wdayIndex"
              >{{ wday }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="start_time" label="Эхлэх цаг" sortable />
        <el-table-column prop="end_time" label="Дуусах цаг" sortable />
        <el-table-column label="Эхлэх дуусах огноо" sortable>
          <template
            slot-scope="scope"
            v-if="scope.row.start_date && scope.row.end_date"
          >
            <el-tag size="mini">{{ scope.row.start_date }}</el-tag> -
            <el-tag size="mini">{{ scope.row.end_date }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="Төлбөр">
          <template slot-scope="scope">
            {{ mformatPrice(scope.row.amount) }}₮
          </template>
        </el-table-column>
        <el-table-column prop="add_minute" label="Нэмэгдэх минут" />
        <el-table-column prop="main_type" label="Төрөл" />
        <el-table-column prop="updated_at" label="Шинэчилсэн огноо" sortable />
        <el-table-column fixed="right" label="Үйлдэл">
          <template slot-scope="prop">
            <el-button
              @click="openEditDialog('edit', prop.row)"
              type="text"
              size="small"
              >Засах</el-button
            >
            <el-button @click="deleteDialog(prop.row)" type="text" size="small"
              >Устгах</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="
        'Ачаалалтай цагийн төлбөр ' + (dialog_type == 'add' ? 'нэмэх' : 'засах')
      "
      :visible.sync="editVisible"
    >
      <div v-if="editDialogData">
        <el-form
          :rules="rulesEdit"
          ref="editPickHourRef"
          :model="editDialogData"
          :data="editDialogData"
          label-position="left"
          label-width="130px"
          style="font-size: 12px; width: 400px; margin-left: 50px"
        >
          <el-form-item label="Төрөл" prop="main_type">
            <el-select v-model="editDialogData.main_type">
              <el-option
                v-for="x in ['pick_hour', 'weather']"
                :key="x"
                :label="x"
                :value="x"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Өдөрүүд" prop="weekdays">
            <el-select v-model="editDialogData.weekdays" multiple clearable>
              <el-option
                v-for="x in week"
                :key="x"
                :label="x"
                :value="x"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Эхлэх цаг" prop="start_time">
            <el-time-select
              placeholder="Эхлэх цаг"
              v-model="editDialogData.start_time"
              value-format="HH:mm"
              :picker-options="{
                start: '08:00',
                step: '00:05',
                end: '23:59'
              }"
            >
            </el-time-select>
          </el-form-item>
          <el-form-item label="Дуусах цаг" prop="end_time">
            <el-time-select
              placeholder="Дуусах цаг"
              v-model="editDialogData.end_time"
              value-format="HH:mm"
              :picker-options="{
                start: '08:00',
                step: '00:05',
                end: '23:59'
              }"
            >
            </el-time-select>
          </el-form-item>
          <el-form-item label="Огноо" prop="end_date">
            <el-date-picker
              v-model="start_end_date"
              @change="onChangeStartEndDate"
              clearable
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="To"
              start-placeholder="Эхлэх огноо"
              end-placeholder="Дуусах огноо"
              ariK
            />
          </el-form-item>
          <el-form-item label="Төлбөр" prop="amount">
            <el-currency-input v-model="editDialogData.amount">
            </el-currency-input>
          </el-form-item>
          <el-form-item label="Минут" prop="add_minute">
            <el-input-number v-model="editDialogData.add_minute" :min="0" />
          </el-form-item>
          <!-- <el-form-item label="Нэмэлт">
            <el-input
              v-model="editDialogData.type"
            ></el-input>
          </el-form-item> -->
          <el-form-item>
            <el-button @click="closeDialog()">Болих</el-button>
            <!-- <el-button type="primary" @click="openEditDialog('edit', editDialogData)"> -->
            <!-- <el-button type="primary" @click="editPickHour(editDialogData)"> -->
            <el-button type="primary" @click="savePickHour()">
              Хадгалах
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      title="Хэрэглэгчийн хүргэлтийн төлбөр"
      :visible.sync="deleteVisible"
    >
      <span>Та устгахдаа итгэлтэй байна уу</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">Үгүй</el-button>
        <el-button type="primary" @click="deleteClick(deleteData)"
          >Тийм</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import services from "../helpers/services";
import ElCurrencyInput from "./ElCurrencyInput.vue";
export default {
  name: "PickHour",
  components: {
    ElCurrencyInput
  },
  created() {
    this.getAllPickHour();
  },
  computed: {
    rulesEdit() {
      return {
        main_type: [
          {
            required: true,
            message: "Энэ талбарыг бөглөөгүй байна.",
            trigger: "blur"
          }
        ],
        weekdays: [
          {
            required:
              this.editDialogData &&
              !this.editDialogData?.start_date &&
              !this.editDialogData?.end_date,
            message: "Энэ талбарыг бөглөөгүй байна.",
            trigger: "change"
          }
        ],
        start_time: [
          {
            required: true,
            message: "Энэ талбарыг бөглөөгүй байна.",
            trigger: "change"
          }
        ],
        end_date: [
          {
            required:
              this.editDialogData && this.editDialogData?.weekdays.length == 0,
            message: "Энэ талбарыг бөглөөгүй байна.",
            trigger: "blur"
          }
        ],
        end_time: [
          {
            required: true,
            message: "Энэ талбарыг бөглөөгүй байна.",
            trigger: "change"
          }
        ],
        amount: [
          {
            required: true,
            message: "Энэ талбарыг бөглөөгүй байна.",
            trigger: "change"
          }
        ]
      };
    }
  },
  methods: {
    getAllPickHour() {
      services.getAllPickHour().then(res => {
        if (res.data.status === "success") {
          this.pickHourList = res.data.data;
        } else {
          this.$notify({
            title: "Алдаа",
            message: res.data.message,
            type: "error"
          });
        }
      });
    },
    editPickHour(data) {
      this.$refs.editPickHourRef.validate(valid => {
        if (valid) {
          services.updatePickHour(data.id, data).then(res => {
            if (res.data.message === "Амжилттай засагдлаа") {
              this.$notify({
                title: "Амжилттай",
                message: res.data.message,
                type: "success"
              });
              this.getAllPickHour();
              this.editVisible = false;
            } else {
              this.$notify({
                title: "Алдаа гарлаа",
                message: res.data.message,
                type: "error"
              });
            }
          });
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Шаардлагатай талбаруудыг бөглөнө үү",
            type: "warning"
          });
        }
      });
    },
    savePickHour() {
      this.$refs.editPickHourRef.validate(valid => {
        if (valid) {
          this.showLoader(true);
          if (this.dialog_type == "add") {
            services.createPickHour(this.editDialogData).then(res => {
              if (res.data.status === "success") {
                this.$notify({
                  title: "Амжилттай",
                  message: "Амжилттай хадгаллаа",
                  type: "success"
                });
                this.closeDialog();
                this.getAllPickHour();
              } else {
                this.$notify({
                  title: "Алдаа",
                  message: res.data.message,
                  type: "error"
                });
              }
              this.showLoader(false);
            });
          }
          if (this.dialog_type == "edit") {
            services
              .updatePickHour(this.editDialogData.id, this.editDialogData)
              .then(res => {
                if (res.data.message === "Амжилттай засагдлаа") {
                  this.$notify({
                    title: "Амжилттай",
                    message: res.data.message,
                    type: "success"
                  });
                  this.getAllPickHour();
                  this.closeDialog();
                } else {
                  this.$notify({
                    title: "Алдаа гарлаа",
                    message: res.data.message,
                    type: "error"
                  });
                }
                this.showLoader(false);
              });
          }
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Шаардлагатай талбаруудыг бөглөнө үү",
            type: "warning"
          });
        }
      });
    },
    deleteDialog(value) {
      this.deleteData = Object.assign({}, value);
      this.deleteVisible = true;
    },
    deleteClick(data) {
      services.deletePickHour(data.id).then(response => {
        if (response.data.status === "success") {
          this.$notify({
            title: "Амжилттай",
            message: "Амжилттай устгалаа",
            type: "success"
          });
          this.getAllPickHour();
          this.deleteVisible = false;
        } else {
          this.$notify({
            title: "Алдаа гарлаа",
            message: response.data.message,
            type: "error"
          });
        }
      });
    },
    mformatPrice(value) {
      return parseInt(value)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    closeDialog() {
      this.editVisible = false;
      this.dialog_type = null;
      this.editDialogData = null;
      this.start_end_date = [];
    },
    onChangeStartEndDate(data) {
      if (data && Array.isArray(data) && data.length > 0) {
        this.editDialogData.start_date = data[0];
        this.editDialogData.end_date = data[1];
      } else {
        this.editDialogData.start_date = null;
        this.editDialogData.end_date = null;
      }
    },
    openEditDialog(type, data) {
      this.dialog_type = type;
      this.editDialogData = data;
      if (type === "edit") {
        this.start_end_date =
          data.start_date && data.end_date
            ? [data.start_date, data.start_date]
            : [];
      }
      this.editVisible = true;
    },
    showLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "Уншиж байна. Түр хүлээнэ үү...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    }
  },
  data() {
    return {
      loader: null,
      start_end_date: null,
      deleteVisible: false,
      editVisible: false,
      pickHourList: [],
      week: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      editDialogData: null,
      dialog_type: "",
      addPickHourList: {
        weekday: "",
        weekdays: [],
        start_time: "",
        end_time: "",
        start_date: null,
        end_date: null,
        amount: 0,
        add_minute: 0,
        main_type: null
      },
      deleteData: null
    };
  }
};
</script>
